html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    --bs-body-color: #55565B;
    --bs-dark-color: #7C7E82;
    --bs-dark-rgb: 124, 126, 130;
    --bs-secondary-color: #7C7E82;
    --bs-secondary-rgb: 124, 126, 130;
}

/* h1,h2,h3,h4,h5,h6 { color:#55565B; } */
a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.ms-mt-2 {
    margin-top: 0.20rem
}

tbody tr.over > .sorting_1 {
    background-color: #ffa;
}

.dotdrop {
    position: absolute;
    transform: translate3d(-142px, 20px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}

.overflowx {
    overflow-x: scroll;
}

.stickycol {
    background-color: yellow;
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

.selected {
    background-color: cyan;
}
.dark-layout .selected {
    background-color: #7066e0;
}
.dark-layout p {
    color: #d0d2d6;
}

.border-red {
    border: 1px solid red;
}

.navbar-org-wrapper {
    margin-right: 2rem;
    padding-top: 0.857rem;
    padding-bottom: 0.857rem;
    line-height: 1.5;
}


/**************************   HEADER MAIN-MENU  **********************/
.header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
    margin: 0px 0px
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li {
    padding-top: 0.857rem;
    padding-bottom: 0.857rem
}

.header-navbar .navbar-container ul.navbar-nav li {
    line-height: 1.5
}

.header-navbar.navbar-horizontal ul#main-menu-navigation > li i, .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li i {
    margin-right: 0.5rem;
    height: 17px;
    width: 17px;
    font-size: 1.2rem;
}

.header-navbar.navbar-horizontal ul#main-menu-navigation > li {
    padding-top: 0.857rem;
    padding-bottom: 0.857rem;
}

.header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
    padding: 0.715rem 1.25rem;
    display: flex;
    margin-right: 0.715rem;
}

.header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
    color: #6e6b7b;
    padding: 0 0.5rem;
    position: relative;
}

.header-navbar.navbar-horizontal .dropdown-menu .dropdown-item {
    padding: 0.65rem 1.28rem;
    clear: both;
    color: #6e6b7b;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    position: relative;
    right: 0;
    transition: right ease 0.2s;
}

.header-navbar.navbar-horizontal .dropdown-menu .dropdown-item:hover {
    right: -7px;
    background-color: transparent;
}

.header-navbar.navbar-horizontal ul#main-menu-navigation > li i, .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li i {
    margin-right: 0.5rem;
    height: 17px;
    width: 17px;
    font-size: 1.2rem;
}

.header-navbar .navbar-container {
    padding: 0px 1.4rem !important;
}

.dashboard_nav_item {
    margin-top: -4px;
    color: #6e6b7b !important;
}
.dark-layout .dashboard_nav_item {
    margin-top: -4px;
    color: #d0d2d6 !important;
}


/* 1024 screen main menu */
@media only screen and (max-width: 1450px) and (min-width: 985px) {
    .header-navbar.navbar-horizontal ul#main-menu-navigation > li  {
        margin: auto;
        display: inline-block;
        justify-content: space-between;

    }
    .header-navbar.navbar-horizontal ul#main-menu-navigation > li > a {
        padding: 0.715rem 0rem;
        display: inline-block;
        justify-content: center;

    }

}

/* Small screen main menu*/
@media (max-width: 992px) {
    .dark-layout .header-navbar .navbar-container {
        background-color: #283046 ;
        min-height: 60px;
    }
    .dark-layout .navbar-light .navbar-toggler {
        background-color: #D0D2D6;
    }

    .header-navbar .navbar-container {
        background-color: #ffffff;
        min-height: 60px;
    }
}

@media (min-width: 990px) {
    #mainmenu .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }

    .fixed_nav {
        position: fixed !important;
        width: 100%;
    }

}

@media (max-width: 1199.98px) {
    .horizontal-layout .app-content {
        padding: calc(5.65rem + calc(2rem - 0.8rem)) calc(0rem - 0.8rem) 0 calc(2rem - 0.8rem);
    }
}

@media (max-width: 992px) {
    .header-navbar {
        position: relative !important;
        box-shadow: none !important;
        background: transparent !important;
    }

    .navbar-container {
        padding: 0.8rem 1rem !important;
        font-size: 1.1rem;
    }

    .header-navbar .nav-item i, .header-navbar .nav-item svg {
        height: 1.4rem !important;
        width: 1.5rem !important;
    }

    .header-navbar .dropdown-menu {
        box-shadow: none !important;
        border-radius: 0 !important;
        background: var(--bs-dark);
        width: 100%;
    }

    .header-navbar .dropdown-menu * {
        color: #f6f6f6 !important;
    }

    .main-menu-content .nav-item {
        width: 100%;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .dashboardLocationDetail {
        flex-wrap: wrap;
    }

    .dashboardLocationDetailButtons > div {
        float: left;
    }
    /* dark mode dropdown menu*/
    .dark-layout   .header-navbar .dropdown-menu {
        box-shadow: none !important;
        border-radius: 0 !important;
        background: #161D31;
        width: 100%;
        border: 1px solid #283046;
    }
    .dark-layout .header-navbar.navbar-horizontal .dropdown-menu .dropdown-item:hover {
    background: #a9a9a9;
        right: -7px;
        width: 99%;

    }
}

@media (max-width: 520px ) {
    .logo_alignment {
        position: absolute;
        margin-left: 22%;
        justify-content: center;
    }
    .header-navbar #header_navbar_buttons {
        width: 100%;
        justify-content: inherit;
    }

}

@media only screen and (min-width: 522px) and (max-width: 992px) {
    .logo_alignment {
        position: absolute;
        margin-left: 35%;
        justify-content: center;
    }
}

/* End small screen main menu*/
/**************************  END HEADER MAIN-MENU  **********************/

/****************************   FOOTER  ***********************/

footer.footer span i, footer.footer span svg {
    height: 1.51rem;
    width: 1.51rem;
    font-size: 1.51rem;
    margin-left: 0.5rem;
    color: #ea5455;
}

/**************************  END FOOTER  **********************/
/**************************  LOGS  *************************************/
.log-emergency {
    color: white;
    background-color: #ff0000;
}

.log-alert {
    color: white;
    background-color: #ff531a;
}

.log-critical {
    color: white;
    background-color: #ff9633;
}

.log-error {
    color: white;
    background-color: #ffb61a;
}

.log-warning {
    color: black;
    background-color: #fff700;
}

.log-notice {
    color: black;
    background-color: #94dfff;
}

.log-info {
    color: black;
    background-color: #00b3ff;
}

.log-debug {
    color: white;
    background-color: #004cff;
}

.logbadge {
    position: absolute;
    top: 5px;
    left: 110px;
}

/************************** END LOGS  ***********************************/

.error {
    color: red;
}

.success {
    color: lightgreen;
}

.warning {
    color: orange;
}

.medium-swal {
    width: 50% !important;
}

.sensorNotFound {
    background-color: #e02f2f;
    color: white;
}

.sensorFound {
    background-color: #5aed70;
    opacity: 0.8;
    color: #3d3d3d
}

/* Animations */
@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.pulse {
    animation: pulse 2s infinite;
}

.font-info {
    color: #33b5e5
}

.font-warning {
    color: #ffbb33
}

.font-danger {
    color: #ff4444
}

.font-success {
    color: #00C851
}

/**************************    DATA TABLES   ******************************/
.dataTables_filter {
    float: right !important;
    margin-bottom: 4vh;
}

.dataTables_filter input {
    display: inline-block !important;
    width: auto !important;
}

.dataTables_length select {
    display: inline-block !important
}


.dataTables_length {
    float: right !important;
    margin-bottom: 3vh;
}

.dataTables_processing {
    z-index: 3000;
}

.dataTables_info {
    float: left !important;
    margin-top: 9vh;
}

.dataTables_paginate {
    float: right !important;
    margin-top: 10vh;
}

.apexcharts-canvas, .apexcharts-canvas svg {
    max-width: 100%;
}

table .dropdown-menu-right {

}
.navbar-nav .dropdown-menu {
    min-width: 14rem !important;
}

.revealed {
    border: 2px solid red !important;
}

.paginate_button {
    color: #333;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: rgba(230, 230, 230, 0.1);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(230, 230, 230, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background: -webkit-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -moz-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -ms-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: -o-linear-gradient(top, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(230, 230, 230, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%);
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: .5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: #333;
    border: 1px solid transparent;
    border-radius: 2px;
}


.tablesorter-header {
    cursor: pointer;
    position: relative; /* This is needed for the absolute positioning. */
}

.tablesorter-header::before,
.tablesorter-header::after {
    position: absolute;
    right: 0.75em;
    font-family: 'FontAwesome'; /* Use FontAwesome's font so that you can set the content */
    opacity: 0.3; /* Set opacity to gray out icons by default */
}

.tablesorter-header::before {
    content: '\f0de'; /* Font Awesome's up arrow */
    top: calc(50% - 0.75em); /* Tricky to calculate the top offset */
}

.tablesorter-header::after {
    content: '\f0dd'; /* Font Awesome's down arrow */
    bottom: calc(50% - 0.75em);
}

.tablesorter-header.tablesorter-headerAsc::before,
.tablesorter-header.tablesorter-headerDesc::after {
    opacity: 1 !important; /* When sorting, set full opacity on the direction */
}

@media only screen and (max-width: 1023px) and (min-width: 575px) {
    #tblLocations {
        display: block;
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 768px) {
    .table-responsive {
        overflow: auto !important;
    }
}
.table-responsive {
    overflow-x: unset !important;
}

#tblPermissionResponsive {
    overflow: auto !important;
}



/**************************  END  DATA TABLES *************************************/


#dzconverter {
    border: 7px dashed lightgrey;
    border-radius: 10px;
    font-size: 30px;
}

#dzconverter .dz-message {
    display: flex;
    min-height: 20vh;
    width: 100%;
    align-items: center !important;
    justify-content: center !important;
}

.insight-badge {
    position: relative;
    background-color: red;
    border-radius: 10px;
    color: white;
    padding: 0px 3px 0px 3px;
    right: 12px;
    bottom: 20px;
}

#lp-mc .header-navbar .logo {
    display: block;
    height: 3vh
}

#mainmenu {
    min-height: 71px;
}

.navbar-brand {
    margin-top: -6px;
    transition: all 1s;
}

.horizontal-menu .navbar.header-navbar .navbar-container {
    padding: 0.2rem 2rem;
}

.dark-layout .horizontal-layout .header-navbar {
    background: #283046 !important;
}

.horizontal-menu .header-navbar.navbar-light {
    background: #fff !important;
}

.interactive:hover {
}

.clickable {
    cursor: pointer;
}

.clickable i, .clickable svg {
    transition: all 0.5s ease-in-out;
}

.clickable:hover i, .clickable:hover svg {
    opacity: 0.5;
}

.card.clickable {
    cursor: pointer;
}

.card.clickable:hover, .card.clickable:focus, .card.interactive:hover, .card.interactive:focus {
    box-shadow: 0 6px 24px 0 rgb(34 41 47 / 30%) !important
}

.intro {
    font-size: 1.4rem;
    line-height: 1.8rem;
}

.intro p {
    line-height: 1.8rem;
}

/**************************   NOT FOUND  ******************************************/
#notFoundPage {
    overflow: hidden !important;
}

#notFoundPage .flexbox-container {
    display: flex;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: center;
}

#notFoundPage .btn-primary {
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
    color: #fff !important;
}

#notFoundPage h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873;
}

/**************************    END NOT FOUND  *************************************/

#quick_stats_card .card-body > h2 {
    min-height: 28.8px;
}

.contactFrm {
    text-align: start !important;
}


/**************************   HelpBox  ******************************************/
.helpBoxLink {
    display: block;
    color: #00a0c3;
    font-size: .9rem;
    text-decoration: underline;
    margin: .5rem .25rem;
    padding: 0
}

.helpBoxLink:hover,
.helpBoxLink:focus {
    text-decoration: none
}

.helpBox {
    display: block;
    position: relative;
    border-radius: .357rem;
    padding: 1.5rem;
    margin: 1.5rem 0;
    background: #f1f1f2
}

.helpBox.hidden {
    display: none
}

.helpBox::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #f1f1f2 transparent;
    top: -15px;
    right: 3rem;
    position: absolute
}

.helpBox::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    font-size: 8rem;
    color: #e3e4e5;
    z-index: 1
}

.helpBox .helpBox-content {
    z-index: 2;
    position: relative;
}

.helpBox-text-right {
    text-align: right !important;
    margin-right: 45px;
}
/**************************   End HelpBox  ******************************************/
/**************************   Tour   ******************************************/
.shepherd-element .shepherd-content .shepherd-header {
    background-color: #7367f0 !important;
    padding: 0.38rem 1.2rem !important;
    border-radius: 0.357rem 0.357rem 0 0 !important ;
}
.shepherd-element .shepherd-content .shepherd-header .shepherd-title {
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 1.1rem !important;
}
.shepherd-element .shepherd-content .shepherd-header .shepherd-cancel-icon {
    color: #fff !important;
    font-size: 1.7rem !important;
}
/**************************   End Tour  ******************************************/

.breadcrumb-navLink { color: inherit;}

/**************************   Theme custom classes  ******************************************/

.select_theme_bg {
    background-color: #0a6aa1;
    background: #0a6aa1;
}
.swal2-popup {
    background: #0a6aa1;
}
/**************************   End Theme custom classes  **************************************/

.tour_info_icon {
    display:inline-block;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    vertical-align: bottom;
}

.shepherd-target {
    pointer-events: none !important;
}
.shepherd-element {
    border-radius: 7px !important;
}

.tour_info_icon {
    display:inline-block;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    vertical-align: bottom;
}

.tourBorderRight {
    font-weight: 500;
    color: #636363;
    padding-left: 1rem;
    margin-right: 1rem;
    border-right: 1px solid #d6dce1;
}


/**************************   REPORT PROVIDER  **************************************/
@media (max-width: 768px ) {
    .offcanvas-start { width: 100% !important;}
}

@media (min-width: 769px ) {
    .offcanvas-start { width: 50% !important;}
}

.tooltip_btn {
    position: absolute;
    right: 0;
    top: 0;
}

#main_menu_report_icon {
    margin-right: 0.3rem;
    height: 17px;
    width: 17px;
    font-size: 1.2rem;
}
.report_info_icon {
    text-align: right;
    position: absolute;
    padding: 10px;
    right: 0;
    font-size: 20px;
}

.dark-layout  .offcanvas {
    background-color: #161d31 !important;
}
.dark-layout .accordion .accordion-body {
    background-color: #161d31 !important;
}
/**************************   End REPORT PROVIDER  **************************************/

/************************* LIVE CHAT SUPPORT COMPONENT  *********************************/
#chatSupportContainer .chat_toggle_btn {
    font-family: 'FontAwesome'; /* Use FontAwesome's font so that you can set the content */
    content: '\f590';
    display: block;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 3px solid #7367f0;
    width: 70px;
    height: 70px;
    background: #7367f0;
    border-radius: 100%;
    z-index: 502;
    transition: all .25s ease-in-out;
}

#chatSupportContainer .chat_toggle_btn::before {
    font-family: 'FontAwesome'; /* Use FontAwesome's font so that you can set the content */
    content: '\f590';
    position: absolute;
    font-weight: 600;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 25px;
    color: #fff;
    text-align: center;
    line-height: 70px;
    display: block;
    -webkit-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}
#chatSupportContainer .chat_toggle_btn[aria-expanded="true"]::before {
    content: '\f00d';
    transition: all .25s ease-in-out;
}

@media  only screen and (max-width: 480px)  {
    #chatSupportContainer .active_chat_container {
        position: fixed;
        bottom: 0px;
        background-color: #fff;
        border: 1px solid rgba(34, 41, 47, 0.05);
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0 6px 6px 0 rgba(0,0,0,.02),0 8px 24px 0 rgba(0,0,0,.12)!important;
        /* max-width: 290px; */
        width: 100%;
        height: 100%;
        transition: height .10s ease-in-out!important;
        z-index: 1000;


}
}

@media   only screen and  (min-width: 481px) {
#chatSupportContainer .active_chat_container {
    position: fixed;
    bottom: 95px;
    right: 30px;
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.05);
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 6px 6px 0 rgba(0,0,0,.02),0 8px 24px 0 rgba(0,0,0,.12)!important;
    max-width: 400px;
    width: 400px;
    max-height: 600px;
    height: 600px;
    transition: height .10s ease-in-out!important;
    z-index: 1000;
}
}


#chatSupportContainer #liveChatBodyHeader {
height: 150px;
text-align: center;
position: relative;
overflow: hidden;
transition: height .2s ease-in-out;
background-color: #0c3045;
color: #ffffff;
}

#chatSupportContainer .active-chat-header {
display: flex;
justify-content: space-between;
height: 65px;
background-color: #fff;
padding:2rem 1rem;
border-bottom: 1px solid #ebe9f1;
font-size: 18px;
}

#chatSupportContainer .active_chat_body {
width: 100%;
background-repeat: repeat;
background-size: 210px;
background-color: #ffffff;
height: calc(100% - 70px - 65px);
border-bottom: 1px solid #a9a9a9;
padding: 5px;
float: left;
overflow: auto;

}
#chatSupportContainer .chatDivider {
display: block;
text-align: center;
overflow: hidden;
white-space: nowrap;
margin: 1rem 0;
}

#chatSupportContainer .chatDivider .chatDividerText {
position: relative;
display: inline-block;
font-size: 12px;
font-weight: 700;
color: #1c293b;
padding: 0 1rem;
}



#chatSupportContainer .chat_left {
display: block;
margin: 10px 0 0 0;

}
#chatSupportContainer .chat_left_avatar {
float: left;
display: flex;
position: relative;
margin: 0 auto;
height: auto;
}

#chatSupportContainer .chatContentLeft {
overflow: auto;
overflow-x: hidden;
overflow-y: auto;
padding: 10px 10px;
margin-right: 10px;
bottom: 0 !important;
border-radius: 10px;
text-align: left;
display: block;
box-shadow: 0 4px 8px 0 rgb(34 41 47 / 15%);
max-width: 75%;
color: #fff;
background-image: linear-gradient(80deg, #7367f0, #7367f0);
margin: 5px 50px 5px 50px;
}

#chatSupportContainer .chat_right {
display: block;
margin: 10px 0 0 0;
overflow: hidden;
}


#chatSupportContainer .chatContentRight {
float: right;
padding: 0.7rem 1rem;
margin: 0 1rem 10px 0;
clear: both;
max-width: 75%;
border-radius: 10px;
color: #6e6b7b;
background-color: white;
box-shadow: 0 4px 8px 0 rgb(34 41 47 / 15%);

}


#chatSupportContainer .active_chat_send_input {
width: 80%;
overflow: hidden;
resize: none;
border: none;
background-color: transparent;
outline: none;
padding: 10px;
float: left;

}
#chatSupportContainer .chatSendBtn {
    width: 20%;
    float: right;
    position: relative;
    font-weight: 700;
    font-size: 25px;

}


/************************ END LIVE CHAT SUPPORT COMPONENT  ********************************/

/************************** HEADER NOTIFICATION ******************************************/
#notificationListItems .scrollable-container {
    max-height: 25rem !important;
    position: relative;
    line-height: 1.5;
    overflow-y: auto !important;
}
#notificationListItems .list-item {
border-bottom: 1px solid #ebe9f1 !important;
}
/***************************** END HEADER NOTIFICATION  ***********************************/
/************************** HEADER NOTIFICATION ******************************************/
#notificationListItems .scrollable-container {
    max-height: 25rem !important;
    position: relative;
    line-height: 1.5;
    overflow-y: auto !important;
}
#notificationListItems .list-item {
    border-bottom: 1px solid #ebe9f1 !important;
}
/***************************** END HEADER NOTIFICATION  ***********************************/



#progressContainer {
    position: fixed;
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.05);
    border-radius: 12px;
    box-shadow: 0 6px 6px 0 rgba(0,0,0,.02),0 8px 24px 0 rgba(0,0,0,.12)!important;
    transition: height .10s ease-in-out!important;
    z-index: 20000;
    max-height: 22rem;
    min-height: 5rem;
    min-width: 25rem;
    width: 25rem;
    left: 20px;
    bottom: 20px;
    background-color: #ffff;
    padding: 5px;
}

#progress-title-bar {
    margin-bottom: 0 !important;
    padding: 5px 5px 0 5px
}

#progress-title-bar #close-lager {
    font-size: 2rem;
}


#progress-items-list{
    overflow-y: auto;
}

#progressContainer .progress_content {
   
}

.progress-collapsed {
    position: fixed;
    bottom: 20px;
    left: 20px;
    height: 70px;
    width: 70px;
    cursor: pointer;
    text-align: center;
    z-index: 1059; /**Sweet alerts are 1060 **/
}



/***************************** SCROLLBAR CUSTOM  ***********************************/

::-webkit-scrollbar {
    width: .55rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(27, 27, 27, .4);
    border-radius: 3px;

}
::-webkit-scrollbar-track{
    background: transparent;
}
/***************************** END SCROLLBAR CUSTOM  *********************************/

/******************************** DARK_MODE ******************************************/
/* dark_mode sweet_alert */
.dark-layout .swal2-container.swal2-center>.swal2-popup {
    background-color: #161D31;
}
.dark-layout .swal2-html-container {
    color: #d0d2d6;
}
/* dark_mode tour */
.dark-layout .shepherd-element {
    background-color: #283046;
}
.dark-layout .shepherd-text {
    color: #ffffff;
}
/****************************** END DARK_MODE *************************************/

.scroll-top {
    bottom: 7rem !important;
}
#report_carousel {
    min-height: 400px ;
    max-height: 400px;
}
.report_text_center {
    text-align: center;
    margin: auto;
    display: grid;
    align-items: center;
    height: 100%;
}
.report_sample_img {
    max-height: 400px;
}

/******************************** PREMIUM RIBBON ******************************************/
.premium_ribbon_wrapper {
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    background: #fff;
    width: 100%;
    position: relative; }

.premium_ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 1000;
}
.premium_ribbon::before,
.premium_ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #7367f0;
}
.premium_ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: #7367f0;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
    z-index: 1000;
}

.premium_ribbon_top_left {
    top: -10px;
    left: -10px;
}
.premium_ribbon_top_left::before,
.premium_ribbon_top_left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.premium_ribbon_top_left::before {
    top: 0;
    right: 0;
}
.premium_ribbon_top_left::after {
    bottom: 0;
    left: 0;
}
.premium_ribbon_top_left span {
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
}
/******************************** PREMIUM RIBBON ******************************************/


/****************************** HELP-VIDEO (eklärvideo) **********************************/

#help_video {
    background: #0f0f0f22;

}

.help-video-container {
    width: 95%;
    min-height: 50vh;
    position: relative;
    margin: 0 auto;
}

.help-video-container h1 {
    color: red;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
}
@media (max-width: 767px) {
    .help-video-container .media-container {
        display: block !important;
        margin: 20px auto !important;
    }
    .help-video-container .media-container .video-text-header {
        max-width: 100% !important;
    }
}


.help-video-container .media-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap:  20px;
    margin: 20px auto;
}

.help-video-container .media-container .media {
    height: 300px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
}
.help-video-container .media-container  .video-thumbnail-container {
    background-color: #ffffff;
    height: 200px;
    border-radius: 10px;
    box-shadow:  0 1px 10px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    cursor: pointer;
}

.help-video-container .media-container  .video-text-header {
    position: absolute;
    bottom: 0;
    color: #0c0c0c;
    padding: 10px;
    width: 100%;
    height: 100px;
    font-size: 1.2rem;
    font-weight: 400;
    white-space: normal;
    white-space: nowrap;
    overflow: hidden;
    max-width: 400px;
    white-space: pre-line;
}

.dark-layout .help-video-container .media-container  .video-text-header {
    color:#d0d2d6 ;

}
.help-video-container .media-container  .video-text-header p {
    font-size: 1rem;
    overflow: hidden;
    font-weight: 300;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: auto;
    max-height: 65px;
}

.help-video-container .media-container  .video-title #viewCounter {
    display: block;
    font-size: 1rem;
    font-weight: 400;
}

.help-video-container .media-container .media video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.3s linear;

}
.help-video-container .media-container img {
    object-fit: fill;
    transition: transform 0.3s;
}

.help-video-container .media-container .media:hover img {
    transform: scale(1.1);

}

.help-video-container  .popup-media {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}

.help-video-container .popup-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    max-width: 90%;
    max-height: 70%;
    object-fit: contain;
    border: 3px solid #fff;
    width: 70%;
    height: 70%;
}


.help-video-container .popup-media span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 50px;
    font-weight: bolder;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    user-select: none;

}

.help-video-container .popup-media .video-text-header h3 {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 70%;
}
.help-video-container .popup-media .video-text-header p {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    width: 70%;
    text-align: center;
    font-size: 16px;

}

.videoSearch {
    margin: auto;
    max-width: 800px;
    display: flex;
    padding: 50px 0;
}

.videoSearch .help-video-searchbar {

    font-size: 17px;
    float: left;
    width: 90%;
    border: 1px solid #d8d6de;
    border-right: none !important;
    border-radius: 0 !important;

}
.videoSearch button {
    float: left;
    width: 10%;
    background: #2196F3;
    color: white;
    font-size: 17px;
    cursor: pointer;
    border-radius: 0 !important;
}



.scroll-content {
    display: flex;
    gap: 20px; /* Adjust the gap between items */
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 10px; /* Optional padding to adjust the spacing */
}
.draggable-horizontal-scroll-container {
    overflow: hidden;
}

.scroll-content {
    display: flex;
    gap: 20px; /* Adjust the gap between items */
    flex-wrap: nowrap;
    padding: 10px; /* Optional padding to adjust the spacing */
    cursor: grab;
}

.scroll-content.grabbing {
    cursor: grabbing;
}

.scroll-item {
    min-width: 50px;
    flex: 0 0 auto;
    white-space: nowrap;
    background-color: #f0f0f0;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 4000;
    cursor: pointer;
    border-radius: 10px;
}

.scroll-item-selected {
    background-color: #7165EB;
    color: #f0f0f0;
}
/**************************** END HELP-VIDEO (eklärvideo) ********************************/

#UserAccessOrg .spinner-border {
    position: absolute;
    width: 4rem;
    height: 4rem;
}
#UserAccessOrg .organisationNames{
    display: inline-flex;
    align-items: center;
    margin: 5px 2px auto;
}
.isReportZipped {
    display: inline-block;
    min-height: 4rem !important;
    padding: 1rem 0 1rem 0 !important

}
/**************************** TENANTS  ********************************/
#tenants_heading_elements {
    display: flex !important;
    margin-right: 5rem;
}
/************************** END TENANTS ******************************/

.isReportZipped  > label {
    text-align: left;
    float: left;

}

.isReportZipped input[type="checkbox"] {
    margin-left: 10px;
}

/************************************* SEARCH PAGE ****************************************/
#searchPage .searchInput {
    margin: auto;
    max-width: 800px;
    display: flex;

}

#searchPage .searchInput .help-video-searchbar {

    font-size: 17px;
    float: left;
    width: 90%;
    border: 1px solid #d8d6de;
    border-right: none !important;
    border-radius: 0 !important;

}
#searchPage .searchInput .searchBtn {
    float: left;
    width: 10%;
    background: #2196F3;
    color: white;
    font-size: 17px;
    cursor: pointer;
    border-radius: 0 !important;
}

#searchPage .searchTypeContents {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
}

#searchPage .searchTypeContents .form-check-input {
    cursor: pointer;
}

#searchPage .optionBtn {
    border-radius: 0 !important;

}
#searchPage .optionBtn:focus {
    box-shadow: none !important;
    background-color: white !important;

}

/************************************ END SEARCH PAGE **************************************/

#searchPageCard {
    min-height: 300px;
}

.search-loc-table-responsive {
    overflow-x: auto !important;
}


.custom-select-width {
    width: 200px; /* Adjust width as needed */
}

#tblLocations .font-icon-custom-size {
    font-size: 1.4em;
}

#tblLocations  .disabled-icon {
    opacity: 0.5;
    pointer-events: none;
}

#tblLocationsSpinner .spinner-border {
    width: 4rem !important;
    height: 4rem !important;
}
#countEmptyLoc {
    min-height: 175px;
}


#tblGatewayAudit.dataTable thead th, table.dataTable thead td {
    padding-left: 0px
  }

  /************************************  UVI-SUBSCRIPTION ****************************************/
#uvi_subscription {
    min-height: 620px;
    margin: 30px 0px;


}
#uvi_subscription #subsciptionBtn {
    min-width: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    display: inline-grid;
    justify-content: center;
}
#uvi_subscription #uvis_text {
    font-size: 14px;
    font-weight: bold;
}

/************************************ END UVI-SUBSCRIPTION **************************************/
